import { CustomSVGProps } from '../types/CustomSVGProps.types';

export function IconGlobe48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_971_7579"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="48"
                  height="48">
                <path d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24Z"
                      fill="#C4C4C4"/>
            </mask>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M30.6964 40.0761C32.7019 36.0652 34 30.3843 34 24C34 17.6157 32.7019 11.9348 30.6964 7.92386C28.6312 3.79333 26.1481 2 24 2C21.8519 2 19.3688 3.79333 17.3036 7.92386C15.2981 11.9348 14 17.6157 14 24C14 30.3843 15.2981 36.0652 17.3036 40.0761C19.3688 44.2067 21.8519 46 24 46C26.1481 46 28.6312 44.2067 30.6964 40.0761ZM24 48C30.6274 48 36 37.2548 36 24C36 10.7452 30.6274 0 24 0C17.3726 0 12 10.7452 12 24C12 37.2548 17.3726 48 24 48Z" fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46ZM24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z" fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M48 25H0V23H48V25Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconGlobe24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.1114 4.56357C9.43679 6.41865 9 9.04768 9 12C9 14.9523 9.43679 17.5814 10.1114 19.4364C10.4501 20.3681 10.8299 21.0517 11.2013 21.4836C11.5755 21.9189 11.8488 22 12 22C12.1512 22 12.4245 21.9189 12.7987 21.4836C13.1701 21.0517 13.5499 20.3681 13.8886 19.4364C14.5632 17.5814 15 14.9523 15 12C15 9.04768 14.5632 6.41865 13.8886 4.56357C13.5499 3.63192 13.1701 2.94833 12.7987 2.51636C12.4245 2.08113 12.1512 2 12 2C11.8488 2 11.5755 2.08113 11.2013 2.51636C10.8299 2.94833 10.4501 3.63192 10.1114 4.56357ZM9.68473 1.21251C10.2677 0.534474 11.0466 0 12 0C12.9534 0 13.7323 0.534474 14.3153 1.21251C14.901 1.8938 15.3831 2.82113 15.7682 3.88008C16.5414 6.00622 17 8.87718 17 12C17 15.1228 16.5414 17.9938 15.7682 20.1199C15.3831 21.1789 14.901 22.1062 14.3153 22.7875C13.7323 23.4655 12.9534 24 12 24C11.0466 24 10.2677 23.4655 9.68473 22.7875C9.09901 22.1062 8.61685 21.1789 8.23178 20.1199C7.45864 17.9938 7 15.1228 7 12C7 8.87718 7.45864 6.00622 8.23178 3.88008C8.61685 2.82113 9.09901 1.8938 9.68473 1.21251Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23 13H1V11H23V13Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconGlobe16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 8C12 12.4183 10.2091 16 8 16C5.79086 16 4 12.4183 4 8C4 3.58172 5.79086 0 8 0C10.2091 0 12 3.58172 12 8ZM10 8C10 11.3137 9.10457 14 8 14C6.89543 14 6 11.3137 6 8C6 4.68629 6.89543 2 8 2C9.10457 2 10 4.68629 10 8Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 9L1 9L1 7L15 7V9Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconStarSolid48(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M22.1677 3.19836C22.8668 1.60055 25.1333 1.60055 25.8323 3.19836L31 15.0102L44.1017 16.5516C45.7247 16.7425 46.446 18.6937 45.3377 19.8945L36 30.0102L38.2861 42.9648C38.5759 44.607 36.8468 45.8618 35.3754 45.0771L24 39.0102L12.6247 45.0771C11.1532 45.8618 9.42412 44.607 9.71392 42.9648L12 30.0102L2.6624 19.8945C1.554 18.6937 2.27539 16.7425 3.89832 16.5516L17 15.0102L22.1677 3.19836Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconStarSolid24(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0948 2.5751C11.4547 1.8083 12.5453 1.8083 12.9052 2.5751L15.3992 7.88844L21.0205 8.44907C21.8385 8.53065 22.2141 9.50955 21.6607 10.1174L17.5 14.6868L18.6126 20.5907C18.7682 21.4167 17.8974 22.0545 17.1569 21.657L12 18.8884L6.84312 21.657C6.10261 22.0545 5.23176 21.4167 5.3874 20.5907L6.5 14.6868L2.33933 10.1174C1.78586 9.50955 2.16148 8.53065 2.97949 8.44907L8.60081 7.88844L11.0948 2.5751Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconStarSolid16(props: CustomSVGProps) : JSX.Element{
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5516 1.27872C7.73501 0.907094 8.26493 0.907095 8.44834 1.27872L10.2348 4.89844C10.3076 5.04601 10.4484 5.14829 10.6112 5.17196L14.6058 5.75241C15.016 5.812 15.1797 6.31598 14.8829 6.60525L11.9924 9.42281C11.8746 9.53768 11.8208 9.70318 11.8486 9.86538L12.531 13.8438C12.601 14.2523 12.1723 14.5638 11.8055 14.3709L8.23264 12.4925C8.08698 12.416 7.91296 12.416 7.7673 12.4925L4.19443 14.3709C3.82761 14.5638 3.3989 14.2523 3.46895 13.8438L4.15131 9.86538C4.17913 9.70318 4.12536 9.53768 4.00751 9.42281L1.11699 6.60525C0.820236 6.31598 0.983992 5.812 1.3941 5.75241L5.3887 5.17196C5.55155 5.14829 5.69234 5.04601 5.76517 4.89844L7.5516 1.27872Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}
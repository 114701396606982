import { CustomSVGProps } from '../../types/CustomSVGProps.types';

export function IconPlayVideo48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46ZM24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M36.411 22.1793C37.9742 22.8898 37.9742 25.1102 36.411 25.8207L17.8276 34.2677C16.5034 34.8696 15 33.9016 15 32.447L15 15.553C15 14.0984 16.5034 13.1304 17.8276 13.7323L36.411 22.1793ZM17 32.447L35.5834 24L17 15.553L17 32.447Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconPlayVideo24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" 
             height="24"
             viewBox="0 0 24 24" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M17.411 10.1793C18.9742 10.8898 18.9742 13.1102 17.411 13.8207L9.82759 17.2677C8.50339 17.8696 6.99998 16.9016 6.99998 15.447L6.99998 8.553C6.99998 7.09843 8.50339 6.13036 9.82759 6.73227L17.411 10.1793ZM8.99998 15.447L16.5834 12L8.99998 8.553L8.99998 15.447Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconPlayVideo16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16" 
             viewBox="0 0 16 16" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" 
                  fill={props.fill || 'currentColor'}/>
            <path d="M11.1056 7.55279C11.4741 7.73706 11.4741 8.26296 11.1056 8.44722L6.7236 10.6382C6.39115 10.8044 5.99999 10.5627 5.99999 10.191L5.99999 5.80903C5.99999 5.43733 6.39115 5.19559 6.7236 5.36181L11.1056 7.55279Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

import React from 'react';

import { NavigationDataGroup, NavigationDataItem } from '@/components/navigation/data/NavigationDataItem.type';

import { CinemaDto } from '@cineamo/legacy-frontend-lib/src/models/cinema/CinemaDto.types';

import { cinemaIdOrSlug } from '@/src/helper/cinema-helper';

import { IconFilmSerie24 } from '@cineamo/legacy-frontend-lib/src/icons/categories/ic-filmSerie';
import { IconDashboard24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-dashboard';
import { IconDeals24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-deals';
import { IconDistributor24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-distributor';
import { IconGlobe24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-globe';
import { IconLibrary24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-library';
import { IconMarketingAssistant24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-marketingAssistant';
import { IconPieChart24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-pieChart';
import { IconPricing24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-pricing';
import { IconProfile24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-profile';
import { IconProgram24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-program';
import { IconSeat24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-seat';
import { IconSend24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-send';
import { IconTrend24 } from '@cineamo/legacy-frontend-lib/src/icons/ic-trend';
import { IconPlayVideo24 } from '@cineamo/legacy-frontend-lib/src/icons/symbols/ic-playVideo';

export const cinemaNavigation = (options: { cinema: CinemaDto; isAdmin?: boolean }): NavigationDataGroup[] => {
    const cinema = options.cinema;
    const cinemaId = cinemaIdOrSlug(cinema);
    return [
        {
            label: 'navigation.general',
            children: [
                {
                    title: 'navigation.dashboard',
                    href: '/',
                    icon: <IconDashboard24 />,
                    necessaryPermission: 'cinema_administration'
                }
            ]
        },
        {
            label: 'navigation.operation',
            children: [
                {
                    title: 'navigation.my-cinema',
                    href: `/${cinemaId}/settings`,
                    icon: <IconSeat24 />,
                    necessaryPermission: 'cinema_administration'
                },
                cinema?._embedded?.cinemaConfiguration?.isWebsiteCustomer === true || options.isAdmin === true
                    ? {
                          title: 'navigation.cinema-website',
                          href: `/${cinemaId}/cinema-website`,
                          icon: <IconGlobe24 />,
                          necessaryPermission: 'cinema_administration'
                      }
                    : undefined,
                {
                    title: 'navigation.events',
                    href: `/${cinemaId}/event-requests`,
                    icon: <IconProgram24 />,
                    necessaryPermission: 'cinema_administration'
                },
                {
                    title: 'navigation.showings',
                    href: `/${cinemaId}/showing`,
                    icon: <IconFilmSerie24 />,
                    necessaryPermission: 'cinema_administration'
                },
                {
                    title: 'navigation.custom-content',
                    href: `/${cinemaId}/custom-contents`,
                    icon: <IconPlayVideo24 />,
                    necessaryPermission: 'cinema_administration'
                },
                {
                    title: 'navigation.deals',
                    href: `/${cinemaId}/deals`,
                    icon: <IconDeals24 />,
                    necessaryPermission: 'cinema_administration'
                },
                cinema?._embedded?.cinemaConfiguration?.isKOSCustomer === true
                    ? {
                          title: 'navigation.pricing',
                          href: `/${cinemaId}/pricing`,
                          icon: <IconPricing24 />,
                          necessaryPermission: 'cinema_administration'
                      }
                    : undefined
            ].filter((it) => !!it) as NavigationDataItem[]
        },
        {
            label: 'navigation.movies',
            children: [
                {
                    title: 'navigation.movie-library',
                    href: '/movie-library',
                    icon: <IconLibrary24 />,
                    necessaryPermission: 'cinema_administration'
                },
                {
                    title: 'navigation.distributors',
                    href: '/distributors',
                    icon: <IconDistributor24 />,
                    necessaryPermission: 'cinema_administration'
                }
            ]
        },
        {
            label: 'navigation.analytics-and-marketing',
            children: [
                {
                    title: 'navigation.send-notifications',
                    href: `/${cinemaId}/send-notifications`,
                    icon: <IconSend24 />,
                    necessaryPermission: 'cinema_administration'
                },
                {
                    title: 'navigation.marketing',
                    href: '/marketing',
                    icon: <IconTrend24 />,
                    necessaryPermission: 'cinema_administration',
                    excludedPath: '/marketing-assistant'
                },
                {
                    title: 'navigation.analytics',
                    href: `/${cinemaId}/analytics`,
                    icon: <IconPieChart24 />,
                    necessaryPermission: 'cinema_administration'
                },
                {
                    title: 'navigation.marketing-assistant',
                    href: '/marketing-assistant',
                    icon: <IconMarketingAssistant24 />,
                    necessaryPermission: 'cinema_administration'
                }
            ]
        },
        {
            label: 'navigation.organization',
            children: [
                {
                    title: 'navigation.users',
                    href: `/${cinemaId}/users`,
                    icon: <IconProfile24 />,
                    necessaryPermission: 'cinema_administration'
                }
            ]
        }
    ];
};

import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconDeals48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M30.5667 5.9579L23.9999 0L17.4331 5.9579L8.57298 5.61493L7.3722 14.4L0.364502 19.8324L5.09158 27.334L3.21528 36L11.6584 38.7081L15.7914 46.5526L23.9999 43.2L32.2084 46.5526L36.3414 38.7081L44.7845 36L42.9082 27.334L47.6353 19.8324L40.6276 14.4L39.4268 5.61493L30.5667 5.9579ZM37.6909 7.68362L29.8278 7.988L23.9999 2.70048L18.172 7.988L10.3088 7.68362L9.24315 15.4802L3.02396 20.3014L7.21914 26.9589L5.55397 34.6498L13.047 37.0531L16.715 44.015L23.9999 41.0396L31.2848 44.015L34.9527 37.0531L42.4458 34.6498L40.7806 26.9589L44.9758 20.3014L38.7566 15.4802L37.6909 7.68362Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M29.8945 14.4476L19.8945 34.4476L18.1057 33.5532L28.1057 13.5531L29.8945 14.4476Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M17 22C18.6569 22 20 20.6569 20 19C20 17.3431 18.6569 16 17 16C15.3431 16 14 17.3431 14 19C14 20.6569 15.3431 22 17 22ZM17 24C19.7614 24 22 21.7614 22 19C22 16.2386 19.7614 14 17 14C14.2386 14 12 16.2386 12 19C12 21.7614 14.2386 24 17 24Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M31 32C32.6569 32 34 30.6569 34 29C34 27.3431 32.6569 26 31 26C29.3431 26 28 27.3431 28 29C28 30.6569 29.3431 32 31 32ZM31 34C33.7614 34 36 31.7614 36 29C36 26.2386 33.7614 24 31 24C28.2386 24 26 26.2386 26 29C26 31.7614 28.2386 34 31 34Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconDeals24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.334 3.02491L12 0L8.66595 3.02491L4.16756 2.85078L3.5579 7.31107L0 10.0692L2.4 13.8779L1.44738 18.2777L5.73404 19.6526L7.83244 23.6354L12 21.9332L16.1676 23.6354L18.266 19.6526L22.5526 18.2777L21.6 13.8779L24 10.0692L20.4421 7.31107L19.8324 2.85078L15.334 3.02491ZM18.2054 4.78979L14.6415 4.92775L12 2.53121L9.35853 4.92775L5.79459 4.78979L5.31157 8.32355L2.49275 10.5087L4.3942 13.5262L3.63947 17.0121L7.03567 18.1014L8.69817 21.2568L12 19.9082L15.3018 21.2568L16.9643 18.1014L20.3605 17.0121L19.6058 13.5262L21.5072 10.5087L18.6884 8.32355L18.2054 4.78979Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.643 7.96042L9.64303 17.9604L8.35679 17.1887L14.3568 7.18868L15.643 7.96042Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.5 11.0746C9.05228 11.0746 9.5 10.6268 9.5 10.0746C9.5 9.52227 9.05228 9.07455 8.5 9.07455C7.94772 9.07455 7.5 9.52227 7.5 10.0746C7.5 10.6268 7.94772 11.0746 8.5 11.0746ZM8.5 12.5746C9.88071 12.5746 11 11.4553 11 10.0746C11 8.69384 9.88071 7.57455 8.5 7.57455C7.11929 7.57455 6 8.69384 6 10.0746C6 11.4553 7.11929 12.5746 8.5 12.5746Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.5 16.0746C16.0523 16.0746 16.5 15.6268 16.5 15.0746C16.5 14.5223 16.0523 14.0746 15.5 14.0746C14.9477 14.0746 14.5 14.5223 14.5 15.0746C14.5 15.6268 14.9477 16.0746 15.5 16.0746ZM15.5 17.5746C16.8807 17.5746 18 16.4553 18 15.0746C18 13.6938 16.8807 12.5746 15.5 12.5746C14.1193 12.5746 13 13.6938 13 15.0746C13 16.4553 14.1193 17.5746 15.5 17.5746Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>

    );
}

export function IconDeals16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.2227 2.04771L8 0L5.7773 2.04771L2.77837 1.92984L2.37193 4.94924L0 6.81635L1.6 9.39463L0.964918 12.3731L3.8227 13.3038L5.22163 16L8 14.8477L10.7784 16L12.1773 13.3038L15.0351 12.3731L14.4 9.39463L16 6.81635L13.6281 4.94924L13.2216 1.92984L10.2227 2.04771ZM11.5946 3.89877L9.53012 3.97991L8 2.57026L6.46988 3.97991L4.4054 3.89877L4.12561 5.97734L2.49275 7.26267L3.5942 9.03757L3.15701 11.088L5.12432 11.7287L6.08735 13.5847L8 12.7915L9.91265 13.5847L10.8757 11.7287L12.843 11.088L12.4058 9.03757L13.5072 7.26267L11.8744 5.97734L11.5946 3.89877Z"
                  fill={props.fill || 'currentColor'}/>
            <path
                d="M4.92413 6C4.92413 5.44772 5.36504 5 5.90894 5C6.45283 5 6.89375 5.44772 6.89375 6C6.89375 6.55228 6.45283 7 5.90894 7C5.36504 7 4.92413 6.55228 4.92413 6Z"
                fill={props.fill || 'currentColor'}/>
            <path
                d="M8.86336 10C8.86336 9.44772 9.30427 9 9.84817 9C10.3921 9 10.833 9.44772 10.833 10C10.833 10.5523 10.3921 11 9.84817 11C9.30427 11 8.86336 10.5523 8.86336 10Z"
                fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.29438 10.584L9.23361 4.58397L10.4627 5.41603L6.52349 11.416L5.29438 10.584Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>

    );
}
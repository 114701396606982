import classnames from 'classnames';
import React from 'react';

import { cinemaNavigation } from '@/components/navigation/data/cinemaNavigation';
import { NavigationDataGroup } from '@/components/navigation/data/NavigationDataItem.type';
import NavigationItemGroup from '@/components/navigation/NavigationItemGroup';
import SideNavigationProfile from '@/components/navigation/SideNavigationProfile';

import { Role } from '@cineamo/legacy-frontend-lib/src/constants/role-constants';

import useLastSelectedCinema from '@/src/hooks/useLastSelectedCinema';

import { useUserStore } from '@cineamo/legacy-frontend-lib/src/store/user/userStore';

import { ImageCineamo24 } from '@cineamo/legacy-frontend-lib/src/logos/im-cineamo';

import {
    IconNavHandleSateClose,
    IconNavHandleSateOpen
} from '@cineamo/legacy-frontend-lib/src/icons/menu/ic-navHandle';

type SideBarNavigationProps = {
    isSideBarCollapsed: boolean;
    setIsSidebarCollapsed: (isSideBarCollapsed: boolean) => void;
};

export default function SidebarNavigation(props: SideBarNavigationProps): JSX.Element {
    let navigation: NavigationDataGroup[];

    const { user, userType } = useUserStore();

    const { lastSelectedCinema: cinema } = useLastSelectedCinema(user);

    const isAdmin = userType.includes(Role.ROLE_ADMIN);

    if (isAdmin || userType.includes(Role.ROLE_CINEMA)) {
        navigation = cinemaNavigation({
            cinema: cinema,
            isAdmin: isAdmin
        });
    }

    return (
        <div className="relative flex flex-row w-full h-full">
            <div
                className={classnames(
                    ' h-full text-black bg-white rounded-r-[16px]',
                    'duration-300',
                    { 'w-300': !props.isSideBarCollapsed },
                    { 'w-[68px]': props.isSideBarCollapsed }
                )}>
                <div className="w-full h-full overflow-y-auto my-auto">
                    <div className="flex justify-center p-16">
                        <ImageCineamo24 />
                    </div>
                    <SideNavigationProfile />
                    <nav className="my-auto">
                        {navigation.map((group, index) => (
                            <NavigationItemGroup
                                key={index}
                                navigation={group}
                                isSidebarCollapsed={props.isSideBarCollapsed}
                                setIsSidebarCollapsed={props.setIsSidebarCollapsed}
                            />
                        ))}
                    </nav>
                </div>
            </div>

            {/*Navigation handle for collapse and uncollapse the navigation*/}
            <button
                className="absolute lg:relative -translate-y-24 lg:translate-y-0 -right-[13px] lg:right-auto top-1/2 lg:top-auto cursor-pointer text-gray hover:text-gray-dark "
                onClick={() => props.setIsSidebarCollapsed(!props.isSideBarCollapsed)}>
                {props.isSideBarCollapsed ? <IconNavHandleSateClose /> : <IconNavHandleSateOpen />}
            </button>
        </div>
    );
}

import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconLoading48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 4C32.2013 4 39.2496 8.93638 42.3358 16H35V18H43.0844H45.1719H46V7H44V14.8221C40.5216 7.2545 32.8744 2 24 2C13.9298 2 5.43985 8.76598 2.82813 18H4.9156C7.46413 9.88579 15.0447 4 24 4ZM45.1719 30H43.0844C40.5359 38.1142 32.9553 44 24 44C15.7987 44 8.7504 39.0636 5.66417 32L13 32V30L4.9156 30H2.82813H2V41H4V33.1779C7.47844 40.7455 15.1256 46 24 46C34.0702 46 42.5602 39.234 45.1719 30ZM46 24L46 23.9818V24.0182L46 24Z"
                fill={props.fill || 'currentColor'}/>
        </svg>

    );
}

export function IconLoading24(props: CustomSVGProps): JSX.Element {
    return (
        <svg
            {...props}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 1H21V5.67384C19.0093 2.8469 15.7203 1 12 1C6.96489 1 2.71992 4.38299 1.41407 9H3.51212C4.74763 5.50442 8.08135 3 12 3C15.1204 3 17.8699 4.58803 19.4845 7H15V9H20.4879H22.5859H23V1ZM1 23H3V18.3262C4.99073 21.1531 8.27969 23 12 23C17.0351 23 21.2801 19.617 22.5859 15H20.4879C19.2524 18.4956 15.9187 21 12 21C8.87959 21 6.13008 19.412 4.51555 17H9V15H3.51212H1.41407H1V23Z"
                fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconLoading16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 0.999953H14V3.22697C12.5954 1.46353 10.4296 0.333252 7.9999 0.333252C4.45761 0.333252 1.47643 2.7356 0.596715 5.99992H2.69627C3.50468 3.85723 5.57432 2.33325 7.9999 2.33325C10.0278 2.33325 11.8069 3.3985 12.8082 4.99995H10V6.99995H16V0.999953ZM0 15.0001L2 15V12.7731C3.40465 14.5364 5.57029 15.6666 7.9999 15.6666C11.5422 15.6666 14.5234 13.2642 15.4031 9.99992H13.3035C12.4951 12.1426 10.4255 13.6666 7.9999 13.6666C5.97205 13.6666 4.193 12.6014 3.1917 11.0001H6V8.99995H0V15.0001Z"
                fill={props.fill || 'currentColor'}/>
        </svg>

    );
}
